import Navbar from '../../common/Navbar.vue'
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import { Field, RadioGroup, Radio, Icon } from 'vant'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    Share: Share,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Icon.name]: Icon
  },
  data () {
    return {
      loadingShow: false,
      merchantCode: '',
      type: 'WCC',
      balance: 0,
      amountTips: '100元',
      amount: ''
    }
  },
  mounted () {
    var query = this.$route.query
    this.merchantCode = query.merchantCode
    this.retrieveWallet()
    this.$refs.share.default('main')
  },
  methods: {
    async retrieveWallet () {
      var pd = { merchantCode: this.merchantCode }
      const { data: res } = await this.$http.post(this.UMS_URL + '/business/wallet/retrieveWallet', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.balance = res.data.coinBalance
        this.amountTips = this.balance + '元'
      }
    },
    async createExtract () {
      if (this.amount === '') {
        this.$dialog.alert({
          title: '提示',
          message: '申请金额不能为空'
        })
      } else if (this.amount > this.balance) {
        this.$dialog.alert({
          title: '提示',
          message: '申请金额必须小于可提现金额'
        })
      } else if (this.amount < 1 || this.amount > 200) {
        this.$dialog.alert({
          title: '提示',
          message: '申请金额为1到200元之间'
        })
      } else {
        this.loadingShow = true
        var pd = { merchantCode: this.merchantCode, amount: this.amount, type: this.type }
        const { data: res } = await this.$http.post(this.UMS_URL + '/business/wallet/createWalletExtract', this.$qs.stringify(pd))
        this.loadingShow = false
        if (res.status === '200') {
          this.$router.go(-1)
        } else {
          this.$dialog.alert({
            title: '提示',
            message: res.msg
          })
        }
      }
    }
  }
}
