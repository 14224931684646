<template>
  <div class="page">
    <Navbar title="提现申请" />
    <van-field name="radio" label="单选框">
      <template #input>
        <van-radio-group
          v-model="type"
          direction="horizontal"
          :checked-color="COLOR_M"
        >
          <van-radio name="WCC">微信钱包</van-radio>
          <van-radio name="BAK" disabled>银行卡</van-radio>
        </van-radio-group>
      </template>
    </van-field>
    <van-field
      v-model="amountTips"
      name="可提现金额"
      label="可提现金额"
      readonly
      placeholder="可提现金额"
    />
    <van-field
      v-model="amount"
      name="提现金额"
      type="number"
      label="提现金额"
      placeholder="请输入1-200元范围内的金额"
      :rules="[{ required: true, message: '请填写提现金额' }]"
    />
    <van-row>
      <van-col span="24" class="tips color-s1"
        ><van-icon name="info-o" />
        因微信限制,每日累计最多可提现200元,如有不便还请谅解!</van-col
      >
    </van-row>
    <van-button
      class="button"
      :color="COLOR_M"
      icon="plus"
      size="small"
      @click="createExtract()"
      >提交申请</van-button
    >
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import BusinessCreate from './BusinessCreate.js'
export default {
  ...BusinessCreate
}
</script>
<style lang="less" scoped>
@import './BusinessCreate.less';
</style>
